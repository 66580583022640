/* eslint-disable */

import {
  VIEW_PACKAGE_CONSTANT,
  CREATE_PACKAGE_CONSTANT,
  UPDATE_PACKAGE_CONSTANT,
  VIEW_PARTIAL_PACKAGE_CONSTANT,
} from 'Store/Constant/Constants';

const initial_state = {
  package: [],
  loading: false,
  updatePackage: [],
  createPackage: [],
  partialRefundPackage: []
};

export const ReadPackages = (state = initial_state, action) => {
  switch (action.type) {
    case VIEW_PACKAGE_CONSTANT.VIEW_PACKAGE_LOADING:
      return { ...state, loading: action.payload };
    case VIEW_PACKAGE_CONSTANT.VIEW_PACKAGE_SUCCESS:
      return { ...state, package: action.payload };
    case VIEW_PACKAGE_CONSTANT.VIEW_PACKAGE_ERROR:
      return { ...state, loading: action.payload };
    case UPDATE_PACKAGE_CONSTANT.UPDATE_PACKAGE_LOADING:
      return { ...state, loading: action.payload };
    case UPDATE_PACKAGE_CONSTANT.UPDATE_PACKAGE_SUCCESS:
      return { ...state, updatePackage: action.payload };
    case UPDATE_PACKAGE_CONSTANT.UPDATE_PACKAGE_ERROR:
      return { ...state, loading: action.payload };
    case CREATE_PACKAGE_CONSTANT.CREATE_PACKAGE_LOADING:
      return { ...state, loading: action.payload };
    case CREATE_PACKAGE_CONSTANT.CREATE_PACKAGE_SUCCESS:
      return { ...state, createPackage: action.payload };
    case CREATE_PACKAGE_CONSTANT.CREATE_PACKAGE_ERROR:
      return { ...state, loading: action.payload };
    case VIEW_PARTIAL_PACKAGE_CONSTANT.VIEW_PARTIAL_PACKAGE_LOADING:
      return { ...state, loading: action.payload };
    case VIEW_PARTIAL_PACKAGE_CONSTANT.VIEW_PARTIAL_PACKAGE_SUCCESS:
      return { ...state, partialRefundPackage: action.payload };
    case VIEW_PARTIAL_PACKAGE_CONSTANT.VIEW_PARTIAL_PACKAGE_ERROR:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
