/* eslint-disable */
import { LOGIN_CONSTANTS, SET_USER_FROM_LOCAL } from '../../Constant/Constants';
import { LOGOUT_USER, FETCH_STATIC_DATA } from 'Store/Constant/Constants';

const initial_state = {
  user: {},
  loading: false,
  staticData:[]
};

export const LoginReducer = (state = initial_state, action) => {
  switch (action.type) {
    case LOGIN_CONSTANTS.LOGIN_LOADING:
      return { ...state, loading: action?.payload };

    case LOGIN_CONSTANTS.LOGIN_SUCESS:
      return { ...state, user: action?.payload };
    case SET_USER_FROM_LOCAL:
      return { ...state, user: action?.payload };

    case LOGIN_CONSTANTS.LOGIN_ERROR:
      return { ...state, user: action?.payload };
    case LOGOUT_USER:
      return { ...state, user: initial_state };
    case FETCH_STATIC_DATA:
      return { ...state, staticData:action?.payload };
    default:
      return state;
  }
};


// payload: response?.data?.response_data?.static_data,
